import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { PageNotFoundComponent } from '@elotech/angular-common';
import { PageNotFoundModule } from '@elotech/angular-common';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  {
    path: 'modulos',
    loadChildren: () => import('./modulos/modulos.module').then(m => m.ModulosModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'me',
    loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule),
  },
  { path: '', redirectTo: 'modulos', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule.forRoot(),
    RouterModule.forRoot(routes, { enableTracing: false }),
    PageNotFoundModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
