import { AuthHttp } from '@elotech/angular-common';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CanActivateAdminRoute implements CanActivate {
  constructor(public authHttp: AuthHttp) {}

  canActivate(): Observable<boolean> | boolean {
    return this.authHttp.isAdmin();
  }
}
